<template>
  <div class="table-consultant">
    <div class="header-table-consultant">
      <p class="title-table-consultant">VENDAS POR {{ title }}</p>
      <div class="header-row-consultant">
        <div class="title-header-consultant">{{ title }}</div>
        <div class="title-header-consultant">INGRESSOS</div>
        <div class="title-header-consultant">VENDAS</div>
        <div class="title-header-consultant">RECEITA</div>
        <div class="title-header-consultant">COMISSÃO</div>
      </div>
    </div>
    <div class="my-consultants">
      <div class="items-consultants" v-for="(item, index) in data" :key="index">
        <div class="item-data-consultant">{{ item[title.toLowerCase()] }}</div>
        <div class="item-data-consultant">{{ formatNumber.format(item.ingressos) }}</div>
        <div class="item-data-consultant">{{ currency.format(item.vendas) }}</div>
        <div class="item-data-consultant">{{ currency.format(item.receita) }}</div>
        <div class="item-data-consultant">{{ currency.format(item.comissao) }}</div>
      </div>
    </div>
    <div class="total-row-consultant">
        <div class="total-item-consultant">TOTAL</div>
        <div class="total-item-consultant">{{ formatNumber.format(totalColumn('ingressos')) }}</div>
        <div class="total-item-consultant">{{ currency.format(totalColumn('vendas')) }}</div>
        <div class="total-item-consultant">{{ currency.format(totalColumn('receita')) }}</div>
        <div class="total-item-consultant">{{ currency.format(totalColumn('comissao')) }}</div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'CardMiniConsultants',
  props: ['data', 'title'],
  components: {},
  data () {
    return {}
  },
  mounted () {
  },
  computed: {
    currency () {
      return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    formatNumber () {
      return Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 10 })
    }
  },
  methods: {
    totalColumn (column) {
      return this.data.reduce((total, consultant) => total + consultant[column], 0)
    }
  }
};
</script>

<style lang="scss" scoped>
.table-consultant {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
  background: #ffffff;
  .header-table-consultant {
    height: 80px;
    font-weight: bold;
    .title-table-consultant {
      margin: 0 !important;
      width: 100%;
      height: 40px;
      color: #454548;
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .header-row-consultant {
      width: 100%;
      height: 40px;
      font-size: 14px;
      color: #4981cc;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid #e1e1e1;
      .title-header-consultant {
        width: 16.25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title-header-consultant:first-child {
        width: 35%;
      }
    }
  }
  .my-consultants {
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    .items-consultants:nth-child(odd) {
      background: #4982cc1c;
    }
    .items-consultants {
      width: 100%;
      padding: 3px 0;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .item-data-consultant {
        width: 16.25%;
        font-size: 12px;
      }
      .item-data-consultant:first-child {
        width: 35%;
      }
    }
  }
  .total-row-consultant {
    border-top: 1px solid #e1e1e1;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .total-item-consultant {
      width: 16.25%;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .total-item-consultant:first-child {
      width: 35%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .table-consultant {
    .header-table-consultant {
      .header-row-consultant {
        .title-header-consultant {
          font-size: 10px;
        }
      }
    }
    .my-consultants {
      width: 100%;
      .items-consultants {
        height: 55px;
        padding: 5px 0 !important;
        .item-data-consultant {
          font-size: 10px;
        }
      }
    }
    .total-row-consultant {
      .total-item-consultant {
        font-size: 10px;
      }
    }
  }
}
</style>
