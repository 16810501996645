<template>
    <div class="my-3 my-md-5">
      <div class="container" id="dashboard" style="position: relative;">
        <b-alert
          style="position: absolute;width: 100%;"
          :show="dismissCountDown"
          fade
          dismissible
          variant="warning"
          @dismissed="dismissCountDown=0"
        >
          {{ alertMessage }}
        </b-alert>
        <div class="page-header">
          <h1 class="page-title">
            Dashboard - Consultores
          </h1>
        </div>
        <b-input-group size="sm" class="col-12 col-sm-12 p-0 pb-4">
          <b-input-group-prepend is-text class="firstLabel">
            Consultor:
          </b-input-group-prepend>
          <div style="min-width: 270px;background: white;height: 34px;">
            <v-select
              @input="search()"
              v-model="consultantSelected"
              :options="optionsConsultants"
              :reduce="opt => opt.id_user"
              :clearable="false"
              :get-option-label="(option) => option.consultor">
            </v-select>
          </div>
        </b-input-group>
        <div class="row">
          <form name="day">
            <div class="col-12 form-group filter-day-consultant">
              <div class="selectgroup selectgroup-pills">
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="all" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('all');">Todos os Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="thirty" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('thirty');">30 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="fifteen" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('fifteen');">15 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="seven" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('seven');">7 Dias</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="yesterday" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('yesterday');">Ontem</span>
              </label>
                <label class="selectgroup-item">
                <input type="radio" name="icon-input" class="selectgroup-input" value="today" v-model="form.searchtype">
                <span class="selectgroup-button selectgroup-button-icon" @click="search('today');">Hoje</span>
              </label>
              <label class="selectgroup-itx'em">
                <HotelDatePicker class="item-date-picker" format="DD-MM-YYYY" @confirm="customDateConfirmed()" v-model="customDate" type="date" :clearable="false" confirm range placeholder="Selecionar data customizada"></HotelDatePicker>
              </label>
              </div>

            </div>
          </form>
        </div>
        <hr class="mt-0 pt-0">
        <div class="content-cards-consultants">
          <CardConsultants :data="salesConsultantArray" />
         
            <CardMiniConsultants :data="salesCompanyArray" title="EMPRESA"/>
            <CardMiniConsultants :data="salesEventsArray" title="EVENTO"/>
         
        </div>
      </div>
    </div>
</template>

<script>
import { dashboardService } from "../../../components/common/services/dashboard";
import { func } from "@/functions";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import HotelDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import CardConsultants from '../CardConsultants.vue';
import CardMiniConsultants from '../CardMiniConsultants.vue';

export default {
  name: 'ConsultantsDashboard',
  mixins: [func],
  components: {
    HotelDatePicker,
    Moment,
    CardConsultants,
    CardMiniConsultants,
    vSelect
  },
  data () {
    return {
      isLoaded: false,
      consultantSelected: '',
      customDate: '',
      datepicker: {
        id: 1,
        format: 'DD/MM/YYYY',
        minNights: 0,
        maxNights: 60,
        hoveringTooltip: true,
        displayClearButton: true,
        startDate: new Date('2003-01-01'),
        endDate: new Date('2055-01-01'),
        startingDateValue: new Date(),
        ptBr: {
          night: 'Dia',
          nights: 'Dias',
          'day-names': ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          'check-in': 'Início',
          'check-out': 'Fim',
          'month-names': ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        },
      },
      form: {
        searchtype: 'today',
        id_base: '',
        id_evento: '',
        date: '',
        hour: '',
        selectedDate: {
          start: moment(new Date()).format("DD/MM/YYYY"),
          end: '',
        }
      },
      alertMessage: '',
      dismissCountDown: 0,
      optionsConsultants: [{ consultor: 'TODOS',id_user:'' }],
      salesConsultantArray: [
       
      ],
      salesCompanyArray: [
        
      ],
      salesEventsArray: [
        
      ]
    }
  },
  async mounted () {
       this.search();
        dashboardService.getConsultores(this.getLoggedId()).then(
        response => {
          if (this.validateJSON(response)) {
                  response.users.forEach(e => this.optionsConsultants.push(e))
          }
        },
        error => {
          this.toastError("Falha na execução.");
        }
      );
  },
  computed: {},
  methods: {
    
    async getDataConsultants () {
      this.showWaitAboveAll();
      dashboardService.getDataDashConsultores(this.getLoggedId(),this.form.selectedDate.start, this.form.selectedDate.end,this.consultantSelected).then(
        response => {
          if (this.validateJSON(response)) {
            this.salesConsultantArray = response.salesConsultant;
            this.salesCompanyArray = response.salesCompany;
            this.salesEventsArray = response.salesEvents;
            
          }
          this.hideWaitAboveAll();

        },
        error => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );

      



    },
    async search(type) {
      this.isLoaded = false
      if (!type && this.customDate) { await this.getDataConsultants(); return false }
      this.form.searchtype = type ? type : this.form.searchtype
      let startDate = ''
      this.customDate = ''
      let endDate = moment()
      this.form.selectedDate.start = ''
      this.form.selectedDate.end = ''
      switch (this.form.searchtype) {
        case 'fifteen':
            startDate = moment().subtract(15, "days")
          break
        case 'seven':
            startDate = moment().subtract(7, "days")
          break
        case 'yesterday':
            startDate = moment().subtract(1, "days")
            endDate = ''
          break
        case 'thirty':
            startDate = moment().subtract(30, "days")
          break
        case 'today':
            startDate = moment()
            endDate = ''
          break
        case 'all':
            startDate = moment(new Date('2021-01-01'))
            endDate = moment()
          break
        case '':
            startDate = ''
            endDate = ''
          break
      }

      if (startDate) { this.form.selectedDate.start =  startDate.format("DD-MM-YYYY") }
      if (endDate) { this.form.selectedDate.end = endDate.format("DD-MM-YYYY") }

      await this.getDataConsultants()

      this.isLoaded = true
    },
    async customDateConfirmed() {
      if (this.customDate.length > 1 && this.customDate[0]) {
        this.form.searchtype = ''

        if (moment(this.customDate[0]).isValid()) { this.form.selectedDate.start = moment(this.customDate[0]).format("DD-MM-YYYY") }
        if (moment(this.customDate[1]).isValid()) { this.form.selectedDate.end = moment(this.customDate[1]).format("DD-MM-YYYY") }

        await this.getDataConsultants()
      }
    }
  }
}
</script>

<style lang="scss">
.filter-day-consultant {
  .selectgroup-pills {
    display: flex;
    align-items: center;
  }
}
.content-cards-consultants {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .section-mini-cards-consultants {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
}
</style>