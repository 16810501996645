<template>
  <div class="table-consultant">
    <div class="header-table-consultant-two">
      <p class="title-table-consultant">PAINEL DE VENDAS</p>
      <div class="header-row-consultant-two">
        <div class="title-header-consultant-two">RANKING</div>
        <div class="title-header-consultant-two">FOTO</div>
        <div class="title-header-consultant-two">META</div>
        <div class="title-header-consultant-two">CONSULTOR</div>
        <div class="title-header-consultant-two">INGRESSOS</div>
        <div class="title-header-consultant-two">VENDAS</div>
        <div class="title-header-consultant-two">RECEITAS</div>
        <div class="title-header-consultant-two">COMISSÃO</div>
      </div>
    </div>
    <div class="my-consultants">
      <div class="items-consultants-two" v-for="(item, index) in data" :key="index">
        <div class="item-data-consultant-two">{{ item.ranking }}</div>
        <div class="item-data-consultant-two">
          <img class="image-consultant" :src="item.foto || 'https://cdn-icons-png.flaticon.com/512/3177/3177440.png'" alt="Foto do Consultor" />
        </div>
        <div class="item-data-consultant-two">{{ currency.format(item.meta) }}</div>
        <div class="item-data-consultant-two">{{ item.consultor }}</div>
        <div class="item-data-consultant-two">{{ formatNumber.format(item.ingressos) }}</div>
        <div class="item-data-consultant-two">{{ currency.format(item.vendas) }}</div>
        <div class="item-data-consultant-two">{{ currency.format(item.receita) }}</div>
        <div class="item-data-consultant-two">{{ currency.format(item.comissao) }}</div>
      </div>
    </div>
    <div class="total-row-consultant-two">
      <div class="total-item-consultant-two">TOTAL</div>
        <div class="total-item-consultant-two"></div>
        <div class="total-item-consultant-two">{{ currency.format(totalColumn('meta')) }}</div>
        <div class="total-item-consultant-two"></div>
        <div class="total-item-consultant-two">{{ formatNumber.format(totalColumn('ingressos')) }}</div>
        <div class="total-item-consultant-two">{{ currency.format(totalColumn('vendas')) }}</div>
        <div class="total-item-consultant-two">{{ currency.format(totalColumn('receita')) }}</div>
        <div class="total-item-consultant-two">{{ currency.format(totalColumn('comissao')) }}</div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'CardConsultants',
  props: ['data'],
  components: {},
  data () {
    return {}
  },
  mounted () {
  },
  computed: {
    currency () {
      return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    formatNumber () {
      return Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 10 })
    }
  },
  methods: {
    totalColumn (column) {
      return this.data.reduce((total, consultant) => total + consultant[column], 0)
    }
  }
}
</script>

<style lang="scss">
.table-consultant {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
  background: #ffffff;
  .header-table-consultant-two {
    height: 80px;
    font-weight: bold;
    .title-table-consultant {
      margin: 0 !important;
      width: 100%;
      height: 40px;
      color: #454548;
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .header-row-consultant-two {
      width: 100%;
      height: 40px;
      font-size: 14px;
      color: #4981cc;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid #e1e1e1;
      .title-header-consultant-two:nth-child(1), .title-header-consultant-two:nth-child(2) {
        width: 10%;
      }
      .title-header-consultant-two {
        width: 16%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .my-consultants {
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    .items-consultants:nth-child(odd) {
      background: #4982cc1c;
    }
    .items-consultants-two {
      width: 100%;
      padding: 3px 0;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .item-data-consultant-two:nth-child(1), .item-data-consultant-two:nth-child(2) {
        width: 10%;
      }
      .item-data-consultant-two {
        width: 16%;
        font-size: 13px;
        .image-consultant {
          width: 25px;
          height: 25px;
          border-radius: 14px;
        }
      }
    }
  }
  .total-row-consultant-two {
    border-top: 1px solid #e1e1e1;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .total-item-consultant-two:nth-child(1), .total-item-consultant-two:nth-child(2) {
      width: 10%;
    }
    .total-item-consultant-two {
      width: 16%;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .p-5 {
    padding: 1.5rem 0 !important;
  }
  .table-consultant {
    overflow-x: auto;
    .header-table-consultant-two {
      .header-row-consultant-two {
        width: fit-content;
        .title-header-consultant-two {
          width: 80px !important;
          font-size: 10px;
        }
      }
    }
    .my-consultants {
      width: fit-content;
      overflow-x: auto;
      .items-consultants-two {
        width: fit-content !important;
        height: 55px;
        padding: 5px 0 !important;
        .item-data-consultant-two {
          width: 80px !important;
          font-size: 10px;
        }
      }
    }
    .total-row-consultant-two {
      width: fit-content;
      .total-item-consultant-two {
        width: 80px !important;
        font-size: 10px;
      }
    }
  }
}
</style>
